@import 'src/scss/colors';

$breakpoint-sm: px-to-em(640px);
$breakpoint-md: px-to-em(768px);
$breakpoint-lg: px-to-em(1024px);
$breakpoint-xl: px-to-em(1450px);

.glp-news-section * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.glp-news-section {
  display: flex;
  flex-direction: column;
}

.glp-news-section {
  &--header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    vertical-align: middle;
    text-transform: uppercase;
    color: $glp-news-card-text-color;
    align-items: center;
    padding-top: px-to-em(42px);
  }

  &--body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media (max-width: $breakpoint-sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &--heading {
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: px-to-em(30px);
    color: $glp-news-card-text-color;
  }
}

// Glp news card
.glp-news-card {
  display: flex;
  flex-direction: column;
  min-width: px-to-em(250px);
  padding: px-to-em(40px) px-to-em(10px);
  width: px-to-em(300px);
  @media (min-width: $breakpoint-md) {
    width: px-to-em(380px);
    min-width: px-to-em(232px);
  }
  @media (min-width: $breakpoint-lg) {
    width: px-to-em(440px);
  }
  @media (min-width: $breakpoint-xl) {
    width: px-to-em(580px);
  }
}

.glp-news-card {
  &__header {
    img {
      width: 100%;
      height: auto;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding-top: px-to-em(20px);
    text-align: left;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: px-to-em(14px);
    color: $glp-news-card-text-color;
    padding: 0 0 px-to-em(25px) 0;
  }
  &__description {
    font-style: normal;
    font-weight: 500;
    font-size: px-to-em(12px);
    color: $glp-news-card-text-color;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 70px;

    @media (max-width: $breakpoint-lg) {
      min-height: 80px;
    }
    @media (max-width: $breakpoint-xl) {
      min-height: 70px;
    }
    @media (max-width: $breakpoint-md) {
      min-height: 120px;
    }
    @media (max-width: $breakpoint-sm) {
      min-height: auto;
    }
  }
  &__description-more {
    display: contents;
    overflow: visible !important;
    height: auto !important;
  }

  &__publish {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-style: normal;
    font-weight: 600;
    line-height: px-to-em(15px);
    font-size: px-to-em(12px);
    color: $glp-news-card-text-color;
    padding: px-to-em(50px) 0 px-to-em(20px) 0;
    @media (max-width: $breakpoint-md) {
      padding: px-to-em(20px) 0 px-to-em(20px) 0;
      min-height: 66px;
    }
    &__publisher {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    &__publish-source {
      margin-top: px-to-em(8px);
      font-style: italic;
    }

    &__publisher-space {
      content: '';
      background: $glp-news-card-text-color;
      width: 10px;
      height: 1px;
      margin: px-to-em(5px) px-to-em(3px) px-to-em(3px) 0;
    }
  }

  &__more-link {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding: px-to-em(5px);
    align-self: flex-end;
    font-weight: 600;
    font-size: px-to-em(12px);
    line-height: px-to-em(15px);
    color: $glp-news-card-link-text-color;
  }
}
.glp-price-section {
  background: #edf0f5;
  padding: 0 0 60px 0;
  .glp-news-section--header {
    background: linear-gradient(180deg, #dee3eb 27.93%, #e1e4ea 100%);
    padding: 60px 0 130px 0;
    margin: 0 0 -100px 0;
    @media (max-width: $breakpoint-sm) {
      padding: 60px 0 90px 0;
    }
  }
  .price-card {
    padding: 0 60px;
    @media (max-width: $breakpoint-lg) {
      padding: 0 0;
    }
  }
  .card-align {
    padding: 34px 30px 0 30px;
    @media (max-width: $breakpoint-lg) {
      padding: 34px 0 0 0;
    }
  }
  .card-align-mid {
    padding: 0 10px;
    @media (max-width: $breakpoint-md) {
      padding: 0;
    }
  }
}
