@import 'src/scss/_colors';

.glp-recipe-direction * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.glp-recipe-direction {
  display: flex;
  flex-direction: column;
  display: inline-block;
  margin-left: px-to-em(20px) !important;
  background-color: transparent;

  &__title {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: px-to-em(14px);
    line-height: px-to-em(17px);
    color: $glp-recipe-direction-title-color;
    padding-bottom: px-to-em(20px) !important;
    padding-top: px-to-em(10px) !important;
  }
  &__body {
    ol li {
      counter-increment: muffins;
    }
    ol {
      list-style: none;
      counter-reset: muffins;
      height: 330px;
      overflow-y: auto;
    }
    ol li:before {
      content: counter(muffins) '. ';
      color: #f67a1a;
    }
  }

  &__item {
    padding-top: px-to-em(15px) !important;
    padding-bottom: px-to-em(20px) !important;
    font-weight: 500;
    font-size: px-to-em(14px);
    line-height: px-to-em(20px);
    text-align: left;
    color: $glp-recipe-direction-text-color;
  }
}
