@import 'src/scss/_colors';

$breakpoint-xs: 320px;
$breakpoint-sm: 568px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

.glp-recipe * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.glp-recipe__main {
  position: relative;
  padding: 0 13%;
  @media (max-width: $breakpoint-sm) {
    padding: 0;
  }
  @media (max-width: $breakpoint-md) {
    height: auto;
    padding: 0;
  }
  @media (max-width: $breakpoint-lg) {
    height: auto;
    padding: 0;
  }
}
.glp-recipe {
  position: relative;
  background: #edf0f5;
  border-bottom: 12px solid #e1e4ea;
  &__header {
    position: relative;
    overflow: hidden;
    height: 500px;
    background: url('img/recipe.svg') no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: $breakpoint-xl) {
      height: 460px;
    }
    @media (max-width: $breakpoint-lg) {
      height: 440px;
    }
    @media (max-width: $breakpoint-md) {
      height: 340px;
    }
    // border-radius: 10px;
    img {
      width: 100%;
      height: auto;
      @media (max-width: $breakpoint-sm) {
        width: 220%;
      }
      @media (max-width: $breakpoint-xl) {
        width: 140%;
      }
    }
    &__overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 9;
      top: 0;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 35.42%,
        rgba(0, 0, 0, 0.76) 85.94%
      );
    }
  }

  &__title {
    color: $glp-recipe-text-color;
    font-weight: 900;
    font-size: px-to-em(30px);
    line-height: 37px;

    text-transform: uppercase;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 5%; /* Adjust this value to move the positioned div up and down */
    text-align: center;
    width: 60%;

    @media (max-width: $breakpoint-sm) {
      font-weight: 900;
      font-size: px-to-em(16px);
      line-height: px-to-em(37px);
      top: 5%;
    }
  }
  &__body {
    position: relative;
    padding: px-to-em(10px);
    z-index: 9;
    box-sizing: border-box;
    color: $glp-recipe-text-color;
    margin: -200px 0 0 0;
    padding-bottom: 0;
    @media (max-width: $breakpoint-md) {
      position: relative;
      padding: 0.625em;
      margin: -170px 0 0 0;
      padding-bottom: 0;
    }

    &__top-container {
      display: flex;
      justify-content: space-between;
      padding: px-to-em(10px);
      overflow: auto;

      &__left {
        font-weight: 900;
        font-size: px-to-em(28px);
        line-height: px-to-em(34px);
        color: $glp-recipe-text-color;

        @media (max-width: $breakpoint-sm) {
          font-weight: 900;
          font-size: px-to-em(18px);
          line-height: px-to-em(37px);
          top: 5%;
        }
      }
      &__right {
        font-weight: bold;
        font-size: px-to-em(16px);
        line-height: px-to-em(20px);
        color: $glp-recipe-text-color;
        display: flex;
        width: 30%;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        @media (max-width: $breakpoint-sm) {
          font-size: px-to-em(12px);
          padding-left: px-to-em(20px);
        }

        &__price {
        }

        &__prepare-time {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: px-to-em(8px);
          margin-right: px-to-em(8px);
          margin-right: 1em;
          white-space: nowrap;
          > span {
            padding-left: px-to-em(8px);
          }
        }

        &__favorite {
          padding-right: px-to-em(20px);
        }
      }
    }
    &__bottom-container {
      background: $glp-recipe-text-color;
      box-shadow: 0px 4px 24px rgba(65, 81, 103, 0.21);
      border-radius: px-to-em(10px);
      flex-direction: column;
      display: flex;
      @media (max-width: $breakpoint-md) {
        height: auto;
      }
    }
    &__nutrition {
      display: flex;
      flex-direction: row;
      padding-left: px-to-em(20px);
      padding-top: px-to-em(20px);
      padding-bottom: px-to-em(20px);
    }
    &__sub-container {
      background: $glp-recipe-sub-container-color;
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media (max-width: $breakpoint-md) {
        flex-direction: column;
        display: block;
      }
    }
    &__ingredient {
      flex: 1;
      padding: 1rem;
      overflow: auto;
    }
    &__direction {
      flex: 1;
      padding: 1rem;
      overflow: auto;
    }
  }
}
