@import 'src/scss/_colors';
$breakpoint-xs: px-to-em(340px);
$breakpoint-sm: px-to-em(640px);
$breakpoint-md: px-to-em(768px);
$breakpoint-lg: px-to-em(992px);
$breakpoint-xl: px-to-em(1450px);

.glp-hero-banner * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.glp-hero-banner {
  position: relative;
  width: 100%;
  background: url('img/heroimage.jpg') no-repeat;
  background-size: cover;
  height: 700px;
  &--image {
    display: block;
    width: 100%;
    height: auto;
  }
  &--shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $glp-hero-banner--overlay;
    top: 0;
  }

  &--content {
    position: absolute;
    top: 50%;
    left: 45%;
    text-align: left;
    transform: translate(-50%, -50%);
    width: 80%;
    @media (min-width: $breakpoint-xs) {
      top: 21%;
      width: 90%;
      left: 50%;
    }

    @media (min-width: $breakpoint-sm) {
      top: 50%;
      width: 60%;
    }
    @media (min-width: $breakpoint-md) {
      top: 22%;
      width: 70%;
    }

    @media (min-width: $breakpoint-lg) {
      top: 24%;
      width: 52%;
      left: 41%;
    }
    @media (min-width: $breakpoint-xl) {
      top: 23%;
      width: 37%;
      left: 42%;
    }

    .glp-hero-banner--description {
      font-style: normal;
      font-weight: 500;
      font-size: px-to-em(14px);
      font-style: normal;
      line-height: px-to-em(25px);
      color: $glp-hero-banner--text;
    }

    .glp-hero-banner--title {
      //margin-top: px-to-em(10px);
      font-style: normal;
      font-weight: bold;
      font-size: px-to-em(20px);
      line-height: px-to-em(36px);
      color: $glp-hero-banner--text;
    }
  }
}

@media (min-width: $breakpoint-xs) {
  .glp-hero-banner {
    &--content {
      .glp-hero-banner--description {
        font-size: px-to-em(18px);
      }
      .glp-hero-banner--title {
        font-size: px-to-em(24px);
      }
    }
  }
}
@media (min-width: $breakpoint-sm) {
  .glp-hero-banner {
    &--content {
      .glp-hero-banner--description {
        font-size: px-to-em(24px);
      }
      .glp-hero-banner--title {
        font-size: px-to-em(34px);
      }
    }
  }
}

@media (min-width: $breakpoint-md) {
  .glp-hero-banner {
    &--content {
      .glp-hero-banner--description {
        font-size: px-to-em(28px);
        line-height: px-to-em(27px);
      }
      .glp-hero-banner--title {
        font-size: px-to-em(38px);
        line-height: px-to-em(38px);
      }
    }
  }
}
