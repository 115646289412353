@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.cursor-pointer {
  cursor: pointer;
}

.page-headings {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 1.7vw;
  line-height: 32px;
  color: #364145;
}

.prod-admin .nav-item.nav-link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #6d838b;
}

.prod-admin .nav-item.nav-link.active {
  font-weight: 600;
  color: #248937;
  margin-right: 3vw;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 768px) {
  .text-md-nowrap {
    white-space: nowrap;
  }
}
