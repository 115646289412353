@import "src/scss/_colors";

.glp-link * {
  box-sizing: border-box;
}

.glp-link {
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1;
  color: $glp-link-default--color;
  display: inline-block;
  cursor: pointer;
  > a {
    text-decoration: none;
    &:focus {
      outline: none;
    }
    &:active {
      color: $glp-link-default--color;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  &--disabled {
    opacity: 0.5;
    display: inline-block;
    pointer-events: none;
    text-decoration: none;
  }
  &--underline {
    text-decoration: underline;
  }
  &--small-underline {
    text-decoration: none;
    > a {
      padding-bottom: 5px;
      position: relative;
      &:hover {
        text-decoration: none;
      }
      &::before {
        content: "";
        position: absolute;
        width: 20%;
        height: 1px;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid $glp-link-default--color;
      }
    }
  }
  &--more-icon {
    > a {
      &::after {
        content: "\a0 ››";
      }
    }
  }
}
