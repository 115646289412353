.productImageHeadings {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #364145;
    margin-top: 15px;
    padding-bottom: 0.5rem;
}
