@import "src/scss/_colors";

.glp-badge * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.glp-badge {
  color: $glp-badge--default-color;
  > svg {
    width: px-to-em(30px);
    height: px-to-em(30px);
  }

  &__default {
    > svg path {
      fill: $glp-badge--default-background-color;
    }
    > span {
      background-color: $glp-badge--default-background-color;
      position: absolute;
    }
  }
  &__primary {
    > svg path {
      fill: $glp-badge--primary-background-color;
    }
    > span {
      background-color: $glp-badge--primary-background-color;
      position: absolute;
    }
  }
  &__secondary {
    > svg path {
      fill: $glp-badge--secondary-background-color;
    }
    > span {
      background-color: $glp-badge--secondary-background-color;
      position: absolute;
    }
  }
  &__sm {
    > span {
      margin-left: -1.4em;
      padding: 0.4em;
      border-radius: 50%;
      font-style: normal;
      font-size: px-to-em(9px);
      font-weight: bold;
    }
  }
  &__md {
    > svg {
      width: px-to-em(40px);
      height: px-to-em(40px);
    }
    > span {
      margin-left: -1.4em;
      padding: 0.2em 0.3em;
      border-radius: 50%;
      font-style: normal;
      font-size: px-to-em(12px);
      font-weight: bold;
    }
  }
  &__lg {
    > svg {
      width: px-to-em(50px);
      height: px-to-em(50px);
    }
    > span {
      margin-left: -1.4em;
      padding: 0.2em;
      border-radius: 50%;
      font-style: normal;
      font-size: px-to-em(16px);
      font-weight: bold;
    }
  }
}
