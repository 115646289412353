.product-management {
  font-family: "Montserrat";
  font-style: normal;
  background-image: url("../assets//images/product-management-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  .table-container {
    width: 90%;
    margin: auto;
  }
  .ag-header-cell-text {
    white-space: pre-wrap;
  }
  .product-table-body {
    width: 100%;
  }
  .product-table-body {
    .product-inventory {
      padding: 1.5rem;
      border-radius: 0 0 1rem 1rem;
      position: relative;
    }
    .product-inventory .glp-search {
      position: absolute;
      right: 0;
      top: -127px;
      width: 497px;
    }
  }
  .glp-profile-relative.glp-grid-view {
    border-radius: 1rem;
  }
  .ag-cell {
    display: flex;
    align-items: center;
  }
  .product-name {
    font-weight: 600;
    color: #248937;
  }

  .upc {
    font-weight: 600;
    color: #f67a1a;
  }

  .brand {
    font-weight: 500;
    color: #6d838b;
    text-transform: capitalize;
  }

  .last-modified-date {
    font-weight: 500;
    line-height: 15px;
    color: #f67a1a;
  }

  .last-modified-id {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #f67a1a;
  }

  .size {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #6d838b;
  }

  .contract-id {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #ec1c24;
    a {
      text-decoration: none;
    }
  }

  .glpin {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #6d838b;
  }
}
