@import "src/scss/_colors";
// scale
$glp-checkbox--2x: 1.4em !default;
$glp-checkbox--1x: 1.2em !default;

// z-index
$glp-checkbox--z-index-back: 0 !default;
$glp-checkbox--z-index-between: 1 !default;
$glp-checkbox--z-index-front: 2 !default;

// text
$glp-checkbox--label-text-offset: 1.5em !default;

// box
$glp-checkbox--curve-radius: 20% !default;
$glp-checkbox--box-size: calc(1em + 2px) !default;

// position
$glp-checkbox-top: 8;
$glp-checkbox-top-offset: calc((0% - (100% - 1em)) - #{$glp-checkbox-top * 1%});

// color set
$glp-checkbox-colors: (primary, $glp-checkbox-primary), (secondary, $glp-checkbox-secondary) !default;

.glp-checkbox * {
  box-sizing: border-box;
}

.glp-checkbox input:not([type='checkbox']) {
  display: none;
}

.glp-checkbox {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  white-space: inherit;
  line-height: 1;

  input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: $glp-checkbox--z-index-front;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .glp-checkbox-state {
    label {
      position: initial;
      display: inline-block;
      font-weight: normal;
      margin: 0;
      //text-indent: $glp-checkbox--label-text-offset;
      min-width: $glp-checkbox--box-size;
      text-indent: 0;
      padding-left: 2em;
      &:before,
      &:after {
        content: '';
        width: $glp-checkbox--box-size;
        height: $glp-checkbox--box-size;
        display: block;
        box-sizing: border-box;
        border-radius: 0;
        border: 1px solid transparent;
        z-index: 0;
        position: absolute;
        left: 0;
        //top: $glp-checkbox-top-offset;
        top:0;
        background-color: transparent;
      }

      &:before {
        border-color: $glp-checkbox--default-color;
      }
    }
  }
}

// size of the checkbox
.glp-checkbox.glp-checkbox--base {
  label:before,
  label:after,
  .glp-checkbox-svg,
  .glp-checkbox-icon,
  .glp-checkbox-img {
    font-size: $glp-checkbox--1x !important;
    top:calc(50% - ((1em + 2px)/2)) !important;
    //top: calc((0% - (100% - 1em)) - 20%) !important;
  }
}

.glp-checkbox.glp-checkbox--bigger {
  label:before,
  label:after,
  .glp-checkbox-svg,
  .glp-checkbox-icon,
  .glp-checkbox-img {
    font-size: $glp-checkbox--2x !important;
    top:calc(50% - ((1em + 2px)/2)) !important;
    //top: calc((0% - (100% - 0.6em)) - 20%) !important;
  }
}

// Round behaviour of checkbox
.glp-checkbox.glp-checkbox--round {
  .glp-checkbox-state label {
    &:before,
    &:after {
      border-radius: 100%;
    }
  }

  &.glp-checkbox--icon .glp-checkbox-state .glp-checkbox-icon {
    border-radius: 100%;
    overflow: hidden;

    &:before {
      transform: scale(0.8);
    }
  }
}

// Focus
.glp-checkbox.glp-checkbox--focus {
  input:focus {
    ~ .glp-checkbox-state label:before {
      box-shadow: 0 0 3px 0 rgb(189, 195, 199);
    }
  }
}

// disabled property
.glp-checkbox {
  input {
    &[disabled] {
      cursor: not-allowed;
      display: none;

      & ~ * {
        opacity: .5;
      }
    }
  }
}


// Curve
.glp-checkbox.glp-checkbox--curve {
  .glp-checkbox-state label {
    &:before,
    &:after {
      border-radius: $glp-checkbox--curve-radius;
    }
  }
}

.glp-checkbox {
  @each $name, $color in $glp-checkbox-colors {
    input:checked ~ .glp-checkbox-state.glp-checkbox--#{$name},
    &.glp-checkbox-state.glp-checkbox--#{$name} {
      label:after {
        background: $color !important;
      }

      .glp-checkbox-svg {
        color: #fff;
        stroke: #fff;
      }
    }

    input:checked ~ .glp-checkbox-state.glp-checkbox--#{$name}-o,
    &.glp-checkbox-state.glp-checkbox--#{$name}-o {
      label:before {
        border-color: $color;
      }

      label:after {
        background-color: transparent;
      }

      .glp-checkbox-svg,
      svg {
        color: $color;
        stroke: $color;
      }
    }

    &.glp-checkbox--default:not(.glp-checkbox--fill) input:checked ~ .glp-checkbox-state.glp-checkbox--#{$name}-o label {
      &:after {
        background: $color !important;
      }
    }
  }
}

// Svg Checkbox
.glp-checkbox.glp-checkbox--svg {
  .glp-checkbox-state {
    .glp-checkbox-svg {
      position: absolute;
      font-size: 1em;
      width: $glp-checkbox--box-size;
      height: $glp-checkbox--box-size;
      left: 0;
      z-index: $glp-checkbox--z-index-between;
      text-align: center;
      line-height: normal;
      top: $glp-checkbox-top-offset;
      border: 1px solid transparent;
      opacity: 0;
    }

    svg {
      margin: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      line-height: 1;
    }
  }

  input:checked ~ .glp-checkbox-state .glp-checkbox-svg {
    opacity: 1;
  }
}

