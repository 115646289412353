@import 'src/scss/_colors';

$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$md: 640px;
$lg: 1100px;
$xl: 1400px;
.glp-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #364145;
  padding: 0 10px;
  position: relative;
  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
    padding: 0 40px;
  }
  .arrow-up {
    position: absolute;
    right: 2rem;
    bottom: 1.6rem;
    @media screen and (max-width: $md) {
      right: 1.2rem;
      bottom: 1.3rem;
    }
    .flex {
      display: flex;
      cursor: pointer;
      align-items: baseline;

      span {
        color: #fff;
        font-weight: 300;
        margin-right: 0.4em;
      }
    }
  }
  .glp-newsletter__view {
    width: 100%;
    margin-top: 0;
  }
}
.glp-footer--body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2.5em 0 0 0;
  text-align: left;
  @media screen and (max-width: $md) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 2.5em 0 4em 0;
  }
  .glp-footer-item {
    width: 20%;
    color: #fff;
    padding-right: 1.2em;
    padding-bottom: 1.2em;
    @media screen and (max-width: $lg) {
      padding-bottom: 1.6em;
      min-width: 8.5em;
      padding-right: 0.6em;
    }
    // @media screen and (max-width: $xl) {
    //   width: 11em;
    // }

    .flex {
      display: flex;
    }
    .text-grey {
      color: #6d838b;
      font-size: 12px;
      margin: 1em 0 0 0;
      @media screen and (max-width: $lg) {
        margin: 0.4em 0 0 0;
      }
    }
    .logo-text {
      margin: 1em 0 0 0.7em;
    }
    .list-group {
      a {
        color: #fff;
        font-size: 0.84rem;
        font-weight: 300;
        padding: 0 0 0.3em 0.9em;
        @media screen and (max-width: $md) {
          padding: 0 0 0.3em 0;
        }
      }
    }
    .heading h5,
    .glp-newsletter label {
      font-size: 1rem;
      color: #fff;
      margin: 0 0 0.8em 0;
      font-weight: 300;
    }
    .glp-newsletter {
      padding: 0;
    }
    .dropdown-toggle {
      padding-right: 0;
      background: transparent;
      border: 1px solid #6d838b;
      border-radius: 0;
      height: 51px;
      margin: 0;
      font-size: 0.8em;
      width: 100%;
      text-align: left;
      position: relative;
      &::after {
        position: absolute;
        right: 14px;
        color: #6d838b;
        top: 23px;
        border-top: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-bottom: 0;
        border-left: 0.4em solid transparent;
      }
      &::before {
        content: '';
        width: 1px;
        background: #6d838b;
        height: 51px;
        position: absolute;
        right: 36px;
        top: 0;
      }
    }
    .show > .btn-success.dropdown-toggle {
      background-color: transparent;
      border-color: #6d838b;
      &:focus {
        box-shadow: none;
      }
    }
    .btn-success:not(:disabled):not(.disabled).active,
    .btn-success:not(:disabled):not(.disabled):active,
    .show > .btn-success.dropdown-toggle {
      background-color: transparent;
      border-color: #6d838b;
    }
    .glp-newsletter__description {
      width: 100%;
    }
    .dropdown-item {
      font-size: 0.9em;
    }
  }
}
