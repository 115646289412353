@import "src/scss/colors";

$glp-search-min-height: px-to-em(40px);
$breakpoint-md: px-to-em(576px);
.glp-search * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.glp-search {
  width: 100%;
  margin-left: auto;
  padding: px-to-em(15px);
  flex-direction: column;
  *:focus {
    outline: none;
  }

  &__view {
    height: $glp-search-min-height;
    max-width: px-to-em(450px);
    margin: px-to-em(10px) 0;
    display: flex;
    flex-direction: row;
    margin-top: px-to-em(20px);
    margin-bottom: px-to-em(15px);
    background: $glp-search-background-color;
    border-radius: px-to-em(4px);

    &__text {
      padding-left: px-to-em(15px);
      width: 100%;
      border: none;
      background: $glp-search-background-color;
      border-top-left-radius: px-to-em(4px);
      border-bottom-left-radius: px-to-em(4px);
      font-weight: 500;
      font-size: px-to-em(14px);
      line-height: px-to-em(17px);
      display: flex;
      align-items: center;
      color: $glp-search-placeholder-color;
      &::placeholder {
        color: $glp-search-placeholder-color;
      }
    }
    &__button {
      background: transparent;
      box-shadow: 0 transparent;
      border: 0 solid transparent;
      text-shadow: 0 transparent;
      border-radius: 0 !important;
      border-top-right-radius: px-to-em(4px) !important;
      border-bottom-right-radius: px-to-em(4px) !important;
      padding-left: px-to-em(15px);
      padding-right: px-to-em(15px);
      background: $glp-search-icon-background;
      font-weight: 600;
      font-size: px-to-em(16px);
      line-height: px-to-em(20px);
      color: $glp-search-button-text-color;
      height: px-to-em(40px);
    }
  }
}

.glp-search__overlay-view {
  padding: px-to-em(20px) px-to-em(25px) px-to-em(10px) px-to-em(15px);
  max-width: px-to-em(450px);
  background: transparent;
  box-shadow: 0 0 px-to-em(30px) $glp-search-overlay-shadow-color;
  border-radius: px-to-em(4px);
  overflow: auto;
  > ul::before {
    content: "";
  }

  > ul {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  > li {
    padding: px-to-em(10px) px-to-em(5px);
    cursor: pointer;
  }

  > li:hover {
    background: transparent;
    text-decoration: underline;
  }

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: px-to-em(10px);

    &__nutrition {
      padding: px-to-em(10px);
      font-weight: 500;
      font-size: px-to-em(14px);
      line-height: px-to-em(17px);
    }
  }
}
