@import "src/scss/_colors";

$glp-button-padding: 12px;
$glp-button-active-opacity: 0.6;
$glp-button-disabled-opacity: 0.6;
$glp-button-min-height: px-to-em(36px);
$glp-button-min-width: px-to-em(140px);

.glp-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 0;
  border-radius: px-to-em(50px);
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  font-size: px-to-em(16px);
  line-height: px-to-em(20px);
  cursor: pointer;
  margin: 0;
  outline: 0;
  user-select: none;
  touch-action: manipulation;
  color: $glp-button-text-color;
  padding: 0 px-to-em($glp-button-padding);
  min-height: $glp-button-min-height;
  min-width: $glp-button-min-width;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.glp-button {
  &-fill--primary,
  &-fill--secondary,
  &-fill--danger,
  &-outline--primary,
  &-outline--secondary,
  &-outline--danger {
    &:active {
      opacity: $glp-button-active-opacity;
    }

    &:focus {
      outline: none;
    }
  }
}

.glp-button {
  // fill colors
  &-fill--primary {
    color: $glp-button-text-color;
    background: $glp-button-primary;

    &:hover {
      outline: none;
      text-decoration: none;
      background: $glp-button-primary-color;
    }
  }

  &-fill--secondary {
    color: $glp-button-text-color;
    background: $glp-button-secondary;

    &:hover {
      outline: none;
      text-decoration: none;
      background: $glp-button-secondary-color;
    }
  }

  &-fill--danger {
    color: $glp-button-text-color;
    background: $glp-button-danger;

    &:hover {
      outline: none;
      text-decoration: none;
      background: $glp-button-danger-color;
    }
  }

  &-fill--danger {
    color: $glp-button-text-color;
    background: $glp-button-danger;

    &:hover {
      outline: none;
      text-decoration: none;
      background: $glp-button-danger-color;
    }
  }

  &-fill--info {
    color: $glp-button-text-color;
    background: $glp-button-info;

    &:hover {
      outline: none;
      text-decoration: none;
      background: $glp-button-info-color;
    }
  }

  // Outline colors
  &-outline--primary {
    color: $glp-button-primary-color;
    border: 1px solid $glp-button-primary-color;
    background-color: transparent;

    &:hover {
      outline: none;
      border: 0;
      text-decoration: none;
      color: $glp-button-text-color;
      background: $glp-button-primary;
    }
  }

  &-outline--secondary {
    color: $glp-button-secondary-color;
    border: 1px solid $glp-button-secondary-color;
    background-color: transparent;

    &:hover {
      outline: none;
      border: 0;
      text-decoration: none;
      color: $glp-button-text-color;
      background: $glp-button-secondary;
    }
  }

  &-outline--danger {
    color: $glp-button-danger-color;
    border: 1px solid $glp-button-danger-color;
    background-color: transparent;

    &:hover {
      outline: none;
      border: 0;
      text-decoration: none;
      color: $glp-button-text-color;
      background: $glp-button-danger;
    }
  }

  &-outline--info {
    color: $glp-button-info-color;
    border: 1px solid $glp-button-info-color;
    background-color: transparent;

    &:hover {
      outline: none;
      border: 0;
      text-decoration: none;
      color: $glp-button-text-color;
      background: $glp-button-info;
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: $glp-button-disabled-opacity;

    a & {
      pointer-events: none;
    }
  }

  &--sm {
    min-width: px-to-em(150px);
  }

  &--md {
    min-width: px-to-em(160px);
  }

  &--lg {
    min-width: px-to-em(170px);
  }

  &--label {
    width: 100%;
  }

  &--icon {
    //TODO::
  }
}

