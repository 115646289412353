@import 'src/scss/_colors';

$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1300px;
$breakpoint-xxl: 1920px;

.glp-callout * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.glp-callout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $glp-callout-background-color;
  padding: 0 10px;
  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
    padding: 0 40px;
  }
}

.glp-callout .glp-callout {
  &__left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 70px;
    align-items: center;
    width: 100%;
    overflow: hidden;
    @media (min-width: $breakpoint-md) {
      padding-right: 160px;
      justify-content: center;
    }
    @media (min-width: $breakpoint-lg) {
      justify-content: flex-start;
      width: 50%;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.25em;
    line-height: 1.5em;
    color: $glp-callout-text-color;
    width: 100%;
    margin: 40px 0;

    @media (min-width: $breakpoint-lg) {
      padding-top: 100px;
      padding-left: 20px;
      align-items: flex-start;
      justify-content: flex-start;
      width: 50%;
    }
  }
}

.glp-callout .glp-callout__left .glp-callout__left__section {
  position: relative;
  @media (min-width: $breakpoint-lg) {
    margin-left: 0;
  }
}

.glp-callout .glp-callout__left__section .glp-callout__left {
  &__front-image {
    position: relative;
    padding-top: 60px;
    margin-bottom: -20px;
    background-color: transparent;
    width: 200px;

    @media (min-width: $breakpoint-md) {
      width: 350px;
      padding-top: 130px;
      margin-bottom: -30px;
    }
    @media (min-width: $breakpoint-lg) {
      width: 280px;
      padding-top: 130px;
      margin-bottom: -30px;
    }
    @media (min-width: $breakpoint-xl) {
      width: 350px;
      padding-top: 130px;
      margin-bottom: -30px;
    }
  }

  &__back-image {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 14em;
    @media (min-width: $breakpoint-md) {
      top: 50px;
      left: 140px;
      width: 400px;
    }
    @media (min-width: $breakpoint-lg) {
      top: 60px;
      left: 110px;
      width: 340px;
    }
    @media (min-width: $breakpoint-xl) {
      top: 60px;
      left: 110px;
      width: 400px;
    }
  }
}

.glp-callout .glp-callout__right {
  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media (min-width: $breakpoint-lg) {
      text-align: left;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  &__description {
    text-align: center;
    @media (min-width: $breakpoint-lg) {
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    @media (min-width: $breakpoint-lg) {
      justify-content: flex-start;
    }
  }
}

.glp-callout .glp-callout__right__top .glp-callout__right {
  &__top-content {
    // TODO::
  }

  &__highlight-content {
    font-weight: 600;
    font-size: 1.3em;
    line-height: 2.3em;
    text-transform: uppercase;
    color: $glp-callout-text-color;
    padding-bottom: 30px;
    @media (min-width: $breakpoint-sm) {
      padding-bottom: 40px;
      font-size: 1.5em;
    }
  }
}

.glp-callout
  .glp-callout__right
  .glp-callout__right__buttons
  .glp-callout__buttons {
  &__app-store {
    cursor: pointer;
  }
  &__play-store {
    cursor: pointer;
    margin-left: 10px;
    @media (min-width: $breakpoint-lg) {
      margin-left: 60px;
    }
  }
}
