@import 'src/scss/_colors';

$breakpoint-sm: 340px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
.glp-recipe-ingredient * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.glp-recipe-ingredient {
  display: flex;
  flex-direction: column;
  padding-left: px-to-em(20px);
  padding-right: px-to-em(20px);

  justify-content: space-evenly;
  &__title {
    display: flex;
    font-weight: 600;
    font-size: px-to-em(14px);
    line-height: px-to-em(17px);
    color: $glp-recipe-ingredient-title-color;
    padding-bottom: px-to-em(20px) !important;
    padding-top: px-to-em(10px) !important;
  }

  &__items {
    padding-top: px-to-em(10px);
    height: 330px;
    overflow-y: auto;
  }
  &__item {
    margin-top: px-to-em(10px) !important;
    display: flex;
    justify-content: space-between;
    padding: px-to-em(20px) px-to-em(10px) px-to-em(20px) px-to-em(10px) !important;
    font-weight: 600;
    font-size: px-to-em(14px);
    line-height: px-to-em(17px);
    color: $glp-recipe-ingredient-text-color;
    align-self: center;
    text-align: left;
    background: $glp-recipe-ingredient-item-back-ground-color;
    box-shadow: 0px 0px px-to-em(6px)
      $glp-recipe-ingredient-item-box-shadow-color;
    border-radius: px-to-em(4px);
    @media (max-width: $breakpoint-md) {
      flex-direction: column !important;
      align-items: center;
    }

    &__name {
      @media (max-width: $breakpoint-md) {
        margin-bottom: px-to-em(10px) !important;
      }
    }
    &__quantity {
      @media (max-width: $breakpoint-md) {
        margin-bottom: px-to-em(10px) !important;
      }
      margin-right: px-to-em(20px) !important;
      margin-left: px-to-em(20px) !important;
      color: $glp-recipe-ingredient-item-quantity-color;
    }

    &__calorie {
      //align-self: flex-end;
    }
  }
}
