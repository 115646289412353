@import "src/scss/_colors";

$glp-input--icon-size: calc(1.5em) !default;

.glp-input * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.glp-input {
  margin-right: 1em;
  margin-left: 1em;
  white-space: nowrap;
  line-height: 20px;

  label {
    font-weight: normal;
    display: block;
    margin-bottom: 0.6em;
    font-size: 1em;
    color: $glp-label--color;
  }

  span {
    position: absolute;
    color: $glp-input--error-color;
    font-size: 0.75em;
    margin-top: -12px;
    background-color: white;
    margin-left: 1.2em;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &--error {
    border: 1px solid $glp-input--error-color !important;
  }

  &--label--flat {
    display: block;
    margin: 0 0 0 !important;
    padding: 0 0 0 !important;
    font-size: 1em !important;
    padding-left: 0.2em !important;
    color: $glp-label-flat-color !important;
  }

  &--outline {
    border-bottom-color: $glp-input--outline-border-color;
    border: 1px solid $glp-input--outline-border-color;

    &:focus {
      border: 1px solid $glp-input--outline-border-color !important;
    }
  }

  &--flat {
    border-radius: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    border-bottom: 1px solid $glp-input--flat-border-color;
    background-color: transparent !important;
    padding-left: 0.3em !important;
    min-height: 2.5em !important;

    &:focus {
      border-bottom: 1px solid $glp-input--flat-border-color !important;
    }
  }

  &--control {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0;

    input {
      width: 100%;
      background-color: $glp-input--background-transparent;
      //display: block;
      color: $glp-input--text-color;
      font-size: 1em;
      line-height: 1.2em;
      padding-left: 1.2em;
      border-radius: 0.6em;
      min-height: 2.2em;

      &::placeholder {
        color: $glp-input--placeholder-color;
        opacity: 1;
      }

      &:focus {
        outline: none !important;
      }
    }

    textarea {
      width: 100%;
      background-color: $glp-input--background-transparent;
      display: block;
      color: $glp-input--text-color;
      font-size: 1em;
      line-height: 1.2em;
      border-radius: 0.6em;
      min-height: 3.3em;
      padding: 1.2em;
      resize: none;

      &::placeholder {
        color: $glp-input--placeholder-color;
        opacity: 1;
      }

      &:focus {
        outline: none !important;
        border: 1px solid $glp-input--outline-border-color !important;
      }
    }
  }

  .glp-input--icon {
    width: $glp-input--icon-size;
    height: $glp-input--icon-size;
    position: absolute;
    right: 0.9em;
    cursor: pointer;
  }
}
