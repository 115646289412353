@import "src/scss/_colors";
$breakpoint-xs: px-to-em(376px);
$breakpoint-sm: px-to-em(640px);
$breakpoint-md: px-to-em(768px);
$breakpoint-lg: px-to-em(1024px);
$breakpoint-xl: px-to-em(1450px);
$md: 640px;

$glp-feat-callout-width: px-to-em(290px);
$glp-feat-callout-min-height: px-to-em(770px);
$glp-feat-callout-circle-default-size: px-to-em(130px);
$glp-feat-callout-circle-default-radius: px-to-em(100px);

.glp-feat-callout * {
  padding: 0;
  box-sizing: border-box;
}

.glp-feat-callout {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: $glp-feat-callout-color;
  min-height: $glp-feat-callout-min-height;
  padding-bottom: px-to-em(30px);
  @media (max-width: $breakpoint-lg) {
    min-height: 50.125em;
  }
  @media (max-width: $breakpoint-md) {
    min-height: 68.125em;
  }

  @media (max-width: $breakpoint-sm) {
    min-height: 184.125em;
  }
  @media (max-width: $breakpoint-xs) {
    min-height: 185.125em;
  }
  @media (max-width: 1200px) {
    min-height: 68.125em;
  }
}

.glp-feat-callout .glp-feat-callout {
  &--header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    vertical-align: middle;
    text-transform: uppercase;
    color: $glp-feat-callout-white-color;
    align-items: center;
    padding-bottom: px-to-em(30px);
  }

  &--body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: px-to-em(40px) 0 0;
    @media screen and (max-width: $md) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.glp-feat-callout .glp-feat-callout--header .glp-feat-callout {
  &-heading {
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: px-to-em(30px);
    line-height: px-to-em(37px);
  }

  &-headline {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: px-to-em(20px);
    line-height: px-to-em(28px);
  }
}

.glp-feat-callout .glp-feat-callout--body {
  .glp-feat-callout-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: px-to-em(200px);
    width: $glp-feat-callout-width;

    @media (max-width: $breakpoint-md) {
      padding-bottom: px-to-em(40px);
      min-width: 11.5em;
    }
  }

  .glp-feat-callout-item--title {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: px-to-em(16px);
    line-height: px-to-em(18px);
    color: $glp-feat-callout-white-color;
    padding: px-to-em(40px) 0 px-to-em(20px) 0;
  }
}

.glp-feat-callout .glp-feat-callout--body .glp-feat-callout-item {
  &--description {
    font-style: normal;
    text-align: center;
    font-weight: 500;
    font-size: px-to-em(12px);
    color: $glp-feat-callout-white-color;
  }

  &--circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $glp-feat-callout-circle-default-size;
    width: $glp-feat-callout-circle-default-size;
    border-radius: $glp-feat-callout-circle-default-radius;
    background-color: $glp-feat-callout-white-color;
  }
}
