@import "src/scss/_colors";

.glp-welcome-menu {
  max-width: px-to-em(160px) !important;
  display: flex;
  flex-direction: row;
  position: relative;
  text-decoration: none;
  height: px-to-em(70px);
  a.dropdown-toggle.nav-link {
    padding-right: 0;
  }
  > .dropdown-toggle::after {
    opacity: 0;
  }
  > .dropdown-menu {
    padding: 0;
    background: $glp-welcome-menu-background-color;
    box-shadow: 0 0 px-to-em(20px) $glp-welcome-menu-shadow-color;
    border-radius: px-to-em(10px);
    border: 0;
  }

  > .dropdown-menu :after {
    content: "";
    display: block;
    position: absolute;
    left: 72%;
    bottom: 100%;
    width: 0;
    height: 0;
    border: px-to-em(8px) solid transparent;
    border-bottom-color: $glp-welcome-menu-background-color;
  }

  &__title {
    display: flex;
    justify-content: center;
    &__greeting {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-right: px-to-em(10px);

      &__message {
        font-style: normal;
        font-weight: 500;
        font-size: px-to-em(12px);
        color: $glp-welcome-menu-item-color;
      }

      &__username {
        font-weight: 600;
        font-size: px-to-em(14px);
        color: $glp-welcome-menu-username-color;
        align-self: flex-end;
      }
    }
  }
  &__avatar {
    vertical-align: middle;
    width: px-to-em(50px);
    height: px-to-em(50px);
    border-radius: 50%;
    overflow: hidden;
    > img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__shape {
    width: 0;
    height: 0;
    border-left: px-to-em(5px) solid transparent;
    border-right: px-to-em(5px) solid transparent;
    border-bottom: px-to-em(10px) solid $glp-welcome-menu-background-color;
    align-self: center;
  }

  &__item {
    background: $glp-welcome-menu-background-color;
    box-shadow: px-to-em(0px) px-to-em(0px) px-to-em(30px) $glp-welcome-menu-shadow-color;
    padding-bottom: px-to-em(10px);
    border-radius: px-to-em(10px);
    text-decoration: none;
    > a {
      color: transparent;
    }
    &__view {
      display: flex !important;
      flex-direction: row !important;
      text-decoration: none;
      align-items: center !important;
      padding-top: px-to-em(30px) !important;
      border: none;
      &__icon {
        height: px-to-em(24px);
        width: px-to-em(24px);
        display: flex;
        align-self: center;
        align-items: center;
      }

      &__title {
        font-style: normal;
        font-weight: 600;
        font-size: px-to-em(14px);
        line-height: px-to-em(17px);
        color: $glp-welcome-menu-item-color;
        padding-left: px-to-em(16px);
        display: flex;
        align-self: center;
        align-items: center;
      }
    }
  }
  .dropdown-item:hover {
    color: $glp-welcome-menu-background-color;
    text-decoration: none;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $glp-welcome-menu-background-color;
  }
}
