@import "src/scss/_colors";

$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;

.user-modal {
  .modal-dialog {
    max-width: 830px;
    @media (max-width: $breakpoint-sm) {
      max-width: 100%;
    }
    .modal-content {
      overflow: hidden;
      border-radius: 10px;
    }
    .modal-body {
      padding: 0;
      .solid {
        background: $glp-checkbox-secondary;
      }
      .employee-body {
        background: #141414 !important;
      }
      .left-side {
        padding: 40px;
        position: relative;
        min-height: 620px;
        @media (max-width: $breakpoint-sm) {
          min-height: 340px;
        }
        .glp-content {
          padding: 60px 0 0 0;
          color: #fff;
          @media (max-width: $breakpoint-sm) {
            padding: 10px 0 0 0;
          }
          h6 {
            font-size: 1.36rem;
            font-weight: 300;
          }
          p {
            font-weight: 300;
            font-size: 0.95rem;
          }
          .list-item {
            position: relative;
            padding-left: 18px;
            font-size: 0.86em;
            &::before {
              display: flex;
              content: "";
              background: red;
              border: 1px solid #fff;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              position: absolute;
              left: 2px;
              top: 5px;
            }
          }
        }
        .glp-link {
          position: absolute;
          bottom: 3.4em;
          right: 2.4em;
          a {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
      .right-side {
        .brand-logo {
          margin: 40px 0 0 -38px;
          background: #fff;
          padding: 4px 4px;
          border-radius: 50px 0 0 50px;
          width: 250px;
          @media (max-width: $breakpoint-sm) {
            margin: 40px 0 0 0;
            padding: 4px 4px 4px 23px;
            width: 270px;
          }
        }
        h6 {
          font-size: 1.5em;
          font-weight: 600;
        }
        .login {
          padding: 0 25px 0 10px;
          @media (max-width: $breakpoint-sm) {
            padding: 0 10px;
          }
        }
        .glp-input {
          label {
            font-weight: normal;
          }
          input {
            height: 56px;
            border: 1px solid #6d838b;
          }
        }
        .val-error {
          padding-left: 1em;
          font-size: 0.9em;
          font-weight: 300;
        }
        .glp-checkbox {
          @media (max-width: $breakpoint-sm) {
            padding: 0 0 0 15px;
          }
          > .glp-checkbox {
            margin-right: 0.3em;
          }
          a {
            color: #f67a1a;
          }
        }
        .forgot {
          font-weight: normal;
        }
        .dot {
          height: 9px;
          width: 9px;
          background-color: #97c83c;
          border-radius: 50%;
          display: inline-block;
          margin: 0 14px 1px 0;
        }
        
      }
    }
  }
}

/* css  written by rohit kumar */ 

