@import "src/scss/colors";
$breakpoint-md: px-to-em(768px);
$breakpoint-lg: px-to-em(1024px);
$breakpoint-xlg: px-to-em(1200px);

.glp-header {
  display: flex;
  background: $glp-header-background-color;
  width: 100%;
  &__left {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__logo {
      max-width: 100%;
      min-width: px-to-em(150px);
      padding-right: 0;
      @media (min-width: $breakpoint-lg) {
        min-width: px-to-em(200px);
        // padding-right: px-to-em(40px);
      }
    }
  }

  &__search.navbar-nav {
    flex: 0.9;
    @media (min-width: $breakpoint-lg) {
      margin-right: px-to-em(30px);
    }
    
  }
  &__right {
    &__link {
      font-style: normal;
      font-weight: 600 !important;
      font-size: px-to-em(14px);
      line-height: px-to-em(17px);
      color: $glp-header-bar-color !important;
      mix-blend-mode: normal;
    }

    &__icons {
      align-items: center;
      display: flex;
      flex-direction: row;

      &__bar {
        width: px-to-em(0.5px);
        height: px-to-em(20px);
        background-color: $glp-header-bar-color;
        margin: 0 px-to-em(20px);
      }
      &__price {
        font-style: normal;
        font-weight: 600 !important;
        font-size: px-to-em(16px);
        line-height: px-to-em(22px);
        text-decoration: none;
        color: $glp-header-price-color;
      }
    }
    &__button {
      margin-left: px-to-em(20px);
    }
  }

  &__login {
    &__order {
      font-style: normal;
      font-weight: 600 !important;
      font-size: px-to-em(14px);
      line-height: px-to-em(17px);
      color: $glp-header-online-color !important;
      mix-blend-mode: normal;
      margin-bottom: px-to-em(16px);
      @media (min-width: $breakpoint-md) {
        margin-right: px-to-em(30px);
        margin-bottom: 0;
      }
    }
  }
}

.glp-type-ahead {
  @media (min-width: $breakpoint-md) {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    flex: 1;
  }
}

.glp-header__inner > .nav-link {
  // padding-left: px-to-em(30px) !important;
  // @media (min-width: $breakpoint-lg) {
  //   padding-left: 0 !important;
  // }
}

.glp-header__login > .nav-link {
  padding-left: px-to-em(10px) !important;
  @media (min-width: $breakpoint-xlg) {
    padding-left: px-to-em(30px) !important;
  }
}

.navbar-light .navbar-nav .nav-link.active {
  color: $glp-header-price-color !important;
}

.navbar-light .navbar-nav .nav-link.active:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 100%;
  border-bottom: px-to-em(2px) solid $glp-header-price-color;
}
