@import "src/scss/colors";

$glp-type-ahead-min-height: px-to-em(40px);
$breakpoint-md: px-to-em(576px);
.glp-type-ahead * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.glp-type-ahead button {
  background: transparent;
  box-shadow: 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 transparent;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  justify-content: center;
  align-items: center;
  left: 0;
}

.glp-type-ahead span {
  font-weight: 600;
  font-size: px-to-em(13px);
  line-height: px-to-em(16px);
  color: $glp-type-ahead-item-add-color;
  white-space: nowrap;
}
.glp-type-ahead {
  // padding: px-to-em(15px);
  flex-direction: column;
  *:focus {
    outline: none;
  }

  &__view {
    height: $glp-type-ahead-min-height;
    // max-width: px-to-em(450px);
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: px-to-em(4px);
    // margin: px-to-em(10px) px-to-em(0px);
    //  margin-top: px-to-em(20px);
    // margin-bottom: px-to-em(15px);

    &__text {
      padding-left: px-to-em(15px);
      width: 100%;
      min-width: 179px;
      border: none;
      background: $glp-type-ahead-text-box-background-color;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      font-weight: 500;
      font-size: px-to-em(14px);
      line-height: px-to-em(17px);
      display: flex;
      align-items: center;
      color: $glp-type-ahead-placeholder-color;
      &::placeholder {
        color: $glp-type-ahead-placeholder-color;
      }
    }
    &__button {
      border-top-right-radius: px-to-em(4px) !important;
      border-bottom-right-radius: px-to-em(4px) !important;
      padding-left: px-to-em(15px);
      padding-right: px-to-em(15px);
      background: $glp-type-ahead-icon-background !important;
      font-weight: 600;
      font-size: px-to-em(16px);
      line-height: px-to-em(20px);
      color: $glp-type-ahead-button-text-color !important;
      height: px-to-em(40px);
      @media screen and (min-width: $breakpoint-md) {
        height: px-to-em(40px);
      }
    }
  }

  .glp-type-ahead__overlay-view {
    padding: px-to-em(20px) px-to-em(25px) px-to-em(10px) px-to-em(15px);
    max-width: px-to-em(630px);
    box-shadow: 0 0 px-to-em(30px) $glp-type-ahead-overlay-shadow;
    border-radius: px-to-em(4px);
    max-height: px-to-em(190px);
    overflow: auto;
    z-index: 999;
    > ul::before {
      content: "";
    }

    > ul {
      list-style-type: none;
      text-align: left;
      margin: 0;
      padding: 0;
    }

    > li {
      padding: px-to-em(10px) px-to-em(5px);
      cursor: pointer;
    }

    > li:hover {
      background: transparent;
      text-decoration: underline;
    }

    &__item {
      display: flex;
      flex-direction: row;
      margin-bottom: px-to-em(20px);
      &__text {
        flex: 4;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        font-size: px-to-em(12px);
        line-height: px-to-em(15px);
        overflow: hidden;
        color: $glp-type-ahead-background-color;
      }
      &__member {
        flex: 4;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        font-size: px-to-em(12px);
        line-height: px-to-em(15px);
        overflow: hidden;
        color: $glp-type-ahead-background-color;
      }
      &__button {
        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-right-radius: px-to-em(4px) !important;
          border-bottom-right-radius: px-to-em(4px) !important;
          padding-left: px-to-em(15px);
          font-weight: 600;
          font-size: px-to-em(16px);
          line-height: px-to-em(20px);
          height: px-to-em(40px);
          @media screen and (min-width: $breakpoint-md) {
            height: px-to-em(40px);
          }
          > span {
            padding-right: px-to-em(10px);
          }
        }
      }
    }

    &__footer {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      border-top: px-to-em(1px) solid $glp-type-ahead-footer-border-color;
      padding-top: px-to-em(10px);
      padding-bottom: px-to-em(10px);
      padding-right: 0;
      > button {
        display: flex;
        flex-direction: row;
        padding-top: px-to-em(5px);
        padding-bottom: px-to-em(5px);
      }
    }
  }
}
