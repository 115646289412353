@import "src/scss/_colors";
$breakpoint-xs: px-to-em(376px);
$breakpoint-sm: px-to-em(640px);
$breakpoint-md: px-to-em(768px);
$breakpoint-lg: px-to-em(1024px);
$breakpoint-xl: px-to-em(1450px);
$glp-feature-card-default-width: 18.4em;

.glp-feature-card * {
  box-sizing: border-box;
}
.glp-feature-relative {
  position: relative;
  .glp-feature-section {
    background: #f6f7fa;
    margin-top: 0;
    padding: 40px 30px 0 20px;
    position: absolute;
    top: -400px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    @media (max-width: $breakpoint-lg) {
      padding: 40px 40px 0 20px;
    }
    @media (max-width: $breakpoint-md) {
      top: -430px;
    }
    @media (max-width: $breakpoint-sm) {
      padding: 40px 0 0 20px;
    }
    h1 {
      font-weight: 900;
      color: #415167;
      font-size: 2rem;
    }
  }
}

.glp-feature-card {
  border-radius: 0.71em;
  padding: 0.71em 1.1em;
  box-shadow: $glp-feature-card-shadow;
  background-color: $glp-feature-card-background-color;
  max-width: $glp-feature-card-default-width;
  min-height: 240px;
  margin-bottom: 1em;
  width: 100%;

  // @media (max-width: $breakpoint-lg) {
  //   width: 13.4em;
  // }
  // @media (max-width: $breakpoint-md) {
  //   width: 12.4em;
  // }
  // @media (max-width: $breakpoint-sm) {
  //   width: 21.5em;
  //   min-height: auto;
  // }
  // @media (max-width: $breakpoint-xs) {
  //   width: 19em;
  // }
}

.glp-feature-card {
  &--body {
    position: relative;
  }
}

.glp-feature-card .glp-feature-card--body {
  .glp-feature-card {
    &--header {
      display: flex;
      color: $glp-feature-card-color;

      .glp-feature-card--img {
        height: 2.4em;
        width: 2.4em;
      }

      .glp-feature-card--title {
        padding-left: 0.84em;
        align-self: flex-end;
        font-size: 1.1em;
        font-style: normal;
        font-weight: bold;
        line-height: 1.28em;
        text-align: left;
        @media (max-width: $breakpoint-md) {
          font-size: 0.9em;
          padding-left: 0.44em;
        }
        @media (max-width: $breakpoint-lg) {
          font-size: 0.9em;
        }
      }
    }

    &--content {
      color: $glp-feature-card-content-color;

      .glp-feature-card--description {
        font-size: 0.85em;
        font-style: normal;
        font-weight: 300;
        line-height: 1.3em;
        text-align: left;
        min-height: 98px;
        @media (max-width: $breakpoint-lg) {
          min-height: 127px;
          font-size: 0.84em;
          line-height: 1.2em;
        }
        @media (max-width: $breakpoint-sm) {
          min-height: auto;
        }
      }
      padding-top: 1em;
    }

    &--footer {
      display: flex;
      padding-top: 2em;
      justify-content: flex-end;
      color: #248937;
      align-items: center;
      span {
        font-weight: 700;
        font-size: 12px;
      }
      em {
        font-style: normal;
        margin-left: 3px;
        font-weight: bold;
        font-size: 10px;
        letter-spacing: -1px;
      }
    }
  }
}
