@import 'src/scss/_colors';

$breakpoint-xs: 320px;
$breakpoint-sm: 568px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
.glp-recipe-nutrition * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.glp-recipe-nutrition {
  display: flex;
  flex-direction: row;
  padding-right: px-to-em(20px);
  background-color: transparent;
  overflow: auto;
  white-space: nowrap;
  @media (min-width: $breakpoint-lg) {
    width: 100%;
  }
  &__title {
    font-weight: 600;
    font-size: px-to-em(14px);
    line-height: px-to-em(17px);

    color: $glp-recipe-nutrition-title-color;
    padding-right: px-to-em(75px) !important;
  }
  &__body {
    @media (min-width: $breakpoint-lg) {
      width: 100%;
    }
  }

  &__items {
    display: flex;
    flex-direction: row;
    overflow: auto;
    white-space: nowrap;
    @media (min-width: $breakpoint-lg) {
      width: 100%;
    }
  }
  &__item {
    flex-direction: column;
    display: inline-block;
    padding-right: px-to-em(0px) !important;
    padding-bottom: px-to-em(4px) !important;
    font-weight: 600;
    font-size: px-to-em(14px);
    line-height: px-to-em(17px);
    color: $glp-recipe-nutrition-text-color;
    align-self: flex-start;
    @media (max-width: $breakpoint-sm) {
      padding-right: px-to-em(20px) !important;
    }
    @media (min-width: $breakpoint-lg) {
      flex: 1 1 auto;
    }
    &__name {
      color: $glp-recipe-nutrition-item-name-color;
    }
    &__quantity {
      margin-top: px-to-em(20px) !important;
      color: $glp-recipe-nutrition-item-quantity-color;
    }
  }
}
