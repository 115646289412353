@import "src/scss/_colors";
$breakpoint-xs: px-to-em(376px);
$breakpoint-sm: px-to-em(640px);
$breakpoint-md: px-to-em(768px);
$breakpoint-lg: px-to-em(1024px);
$breakpoint-xl: px-to-em(1450px);

$glp-price-card-sm: 350px;
$glp-price-card-md: 270px;
$glp-price-card-lg: 280px;

.glp-price-card * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.glp-price-card {
  display: flex;
  flex-direction: column;
  // max-width: px-to-em(280px);
  border-radius: px-to-em(10px);
  background: $glp-price-card-background;
  box-shadow: $glp-price-card-box-shadow;
  .triangle-border {
    border-radius: 7px 7px 0 0;
    border-right: 1px solid #f67a1a;
    border-left: 1px solid #f67a1a;
  }
  img {
    width: 100%;
    max-width: 100%;
  }
  .inverted-triangle {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;

    .glp-price-card__header__text {
      font-weight: bold;
      font-size: 1.25rem;
      text-align: center;
    }
    .glp-price-card__header__help {
      font-size: 0.8rem;
    }
  }
}

.glp-price-card {
  &--default {
    .glp-price-card__body {
      border: 1px solid $glp-price-card-default-color;
      border-top-color: transparent;
    }
  }

  &--primary {
    .glp-price-card__body {
      border: 1px solid $glp-price-card-primary-light-color;
      border-top-color: transparent;
    }
  }

  &--secondary {
    .glp-price-card__body {
      border: 1px solid $glp-price-card-secondary-color;
      border-top-color: transparent;
    }
    @media (max-width: $breakpoint-md) {
      margin-bottom: 34px;
    }
  }

  &--info {
    .glp-price-card__body {
      border: 1px solid $glp-price-card-info-color;
      border-top-color: transparent;
    }
  }

  &--sm {
    width: px-to-em($glp-price-card-sm) !important;
  }

  &--md {
    width: px-to-em($glp-price-card-md) !important;
  }

  &--lg {
    width: px-to-em($glp-price-card-lg) !important;
  }
}

.glp-price-card__header {
  display: flex;
  height: px-to-em(65px);
  justify-content: center;
  align-items: center;
  border-radius: px-to-em(10px) px-to-em(10px) 0 0;

  > .glp-price-card__header__text {
    font-style: normal;
    font-weight: bold;
    font-size: px-to-em(20px);
    line-height: px-to-em(24px);
    text-align: center;
    text-transform: uppercase;
    color: $glp-price-card-text-color;
  }

  > .glp-price-card__header__help {
    font-style: normal;
    font-weight: bold;
    font-size: px-to-em(14px);
    line-height: px-to-em(24px);
    text-align: center;
    text-transform: uppercase;
    color: $glp-price-card-text-color;
  }
}

.glp-price-card__header {
  &--default {
    background: $glp-price-card-default;
  }

  &--primary {
    background: $glp-price-card-primary;
  }

  &--secondary {
    background: $glp-price-card-secondary;
  }

  &--info {
    background: $glp-price-card-info;
  }
}

.glp-price-card__header__active {
  flex-direction: column;
  height: px-to-em(100px);
  justify-content: space-evenly;
  align-items: center;
}

.glp-price-card__body {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  font-size: px-to-em(16px);
  line-height: px-to-em(20px);
  color: $glp-price-card-gray-color;
  padding: px-to-em(2px) px-to-em(4px);
  border-radius: 0 0 px-to-em(10px) px-to-em(10px);
}

.glp-price-card__body {
  &__content {
    margin: px-to-em(0px) px-to-em(10px);
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: px-to-em(25px) 0 px-to-em(15px) 0;
  }

  &--default {
    background: $glp-price-card-default;
    border: px-to-em(1px) solid $glp-price-card-default-color;
  }

  &--primary {
    font-style: normal;
    font-weight: bold;
    font-size: px-to-em(30px);
    line-height: px-to-em(24px);
    text-align: center;
    color: $glp-price-card-primary-light-color;
    padding: px-to-em(4px) 0;
  }

  &--secondary {
    font-style: normal;
    font-weight: bold;
    font-size: px-to-em(24px);
    line-height: px-to-em(24px);
    text-align: center;
    padding: px-to-em(4px) 0;
    color: $glp-price-card-secondary-color;
  }

  &--info {
    font-style: normal;
    font-weight: bold;
    font-size: px-to-em(24px);
    line-height: px-to-em(24px);
    text-align: center;
    padding: px-to-em(4px) 0;
    color: $glp-price-card-info-color;
  }

  &__text {
    margin-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: px-to-em(16px);
    line-height: px-to-em(20px);
    text-align: center;
    color: $glp-price-card-gray-color;
  }
  &__top {
    padding: 0 10px;
  }
}

.glp-price-card__feature {
  margin-top: 10px;
  list-style: none;

  &:first-child {
    border-top: 1px solid $glp-price-card-gray-color;
  }

  .glp-price-card__feature__item {
    padding: px-to-em(14px);
    font-style: normal;
    font-weight: 500;
    font-size: px-to-em(16px);
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid $glp-price-card-gray-color;
    color: $glp-price-card-gray-color;
  }
}

.glp-price-card__border {
  &--primary {
    border-left: 1px solid $glp-price-card-primary-color;
    border-right: 1px solid $glp-price-card-primary-color;
  }
  &--secondary {
    border-left: 1px solid $glp-price-card-secondary-color;
    border-right: 1px solid $glp-price-card-secondary-color;
  }
  &--info {
    border-left: 1px solid $glp-price-card-info-color;
    border-right: 1px solid $glp-price-card-info-color;
  }
}

.glp-price-card__popular {
  border-left: calc(#{$glp-price-card-sm} / 2 + 0px) solid transparent;
  border-right: calc(#{$glp-price-card-sm} / 2 + 0px) solid transparent;
  @media (max-width: $breakpoint-lg) {
    border-left: calc(230px / 2 + 0px) solid transparent;
    border-right: calc(230px / 2 + 0px) solid transparent;
  }
  @media (max-width: $breakpoint-md) {
    border-left: calc(190px / 2 + 0px) solid transparent;
    border-right: calc(190px / 2 + 0px) solid transparent;
  }
  @media (max-width: $breakpoint-sm) {
    border-left: calc(380px / 2 + 0px) solid transparent;
    border-right: calc(380px / 2 + 0px) solid transparent;
  }
  @media (max-width: $breakpoint-xs) {
    border-left: calc(340px / 2 + 0px) solid transparent;
    border-right: calc(340px / 2 + 0px) solid transparent;
  }
  &--primary {
    border-top: px-to-em(30px) solid $glp-price-card-primary-color;
  }

  &--secondary {
    border-top: px-to-em(30px) solid $glp-price-card-secondary-color;
  }

  &--info {
    border-top: px-to-em(30px) solid $glp-price-card-info-color;
  }
}
