@import 'src/scss/_colors';
$breakpoint-sm: px-to-em(640px);
$breakpoint-md: px-to-em(768px);
$breakpoint-lg: px-to-em(1024px);
$breakpoint-xl: px-to-em(1450px);
$glp-newsletter-min-width: px-to-em(250px);
$glp-newsletter-min-height: px-to-em(50px);
$glp-newsletter-description-width: px-to-em(350px);

.glp-newsletter * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.glp-newsletter {
  background: $glp-news-letter-background-color;
  padding: px-to-em(15px);
  display: flex;
  flex-direction: column;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: px-to-em(16px);
    line-height: px-to-em(20px);
    color: $glp-news-letter-base-color;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: px-to-em(12px);
    color: $glp-news-letter-description-color;
    width: $glp-newsletter-description-width;
  }
  &__view {
    height: $glp-newsletter-min-height;
    margin: px-to-em(10px) px-to-em(0px);
    width: $glp-newsletter-min-width;
    display: flex;
    flex-direction: row;
    margin-top: px-to-em(20px);
    margin-bottom: px-to-em(15px);

    &__text {
      padding-left: px-to-em(15px);
      width: 100%;
      border: px-to-em(1px) solid $glp-news-letter-border-color;
      font-size: px-to-em(16px);
      line-height: px-to-em(20px);
      color: $glp-news-letter-placeholder-color;
      @media (min-width: $breakpoint-md) {
        padding-left: px-to-em(4px);
      }
      &::placeholder {
        // font-style: normal;
        // font-weight: normal;
        // font-size: px-to-em(16px);
        // line-height: px-to-em(20px);
        // color: $glp-news-letter-placeholder-color;
      }
    }
    &__icon {
      width: 30%;
      height: 100%;
      background: $glp-news-letter-icon-background;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: unset;
    }
  }
}
