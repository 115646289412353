@import "src/scss/_mixin";

* {
  font-family: "Montserrat";
}

// glp link color
$glp-link-default--color: #248937;

// glp checkbox colors
$glp-checkbox-default: #ffffff !default;
$glp-checkbox--default-color: #6d838b;
$glp-checkbox-primary: linear-gradient(0deg, #f3521e 0%, #f67a1a 100%);
$glp-checkbox-secondary: linear-gradient(180deg, #5da839 0%, #248937 100%);

//glp price card colors
$glp-price-card-default: linear-gradient(180deg, #f6f7fa 0%, #f6f7fa 100%);
$glp-price-card-primary: linear-gradient(0deg, #f3521e 0%, #f67a1a 100%);
$glp-price-card-secondary: linear-gradient(180deg, #5da839 0%, #248937 100%);
$glp-price-card-info: linear-gradient(180deg, #0ad0eb 0%, #087ec2 100%);
$glp-price-card-primary-light-color: #f67a1a;
$glp-price-card-primary-color: #f3521e;
$glp-price-card-secondary-color: #248937;
$glp-price-card-info-color: #087ec2;
$glp-price-card-text-color: #ffffff;
$glp-price-card-default-color: #f6f7fa;
$glp-price-card-box-shadow: 0 4px 24px rgba(65, 81, 103, 0.21);
$glp-price-card-background: #f6f7fa;
$glp-price-card-gray-color: #415167;

// Breadcrumb color
$glp-breadcrumb--default-color: #248937;
$glp-breadcrumb--inactive-color: #6d838b;

//glp-hero-banner
$glp-hero-banner--text: #ffffff;
$glp-hero-banner--overlay: rgba(0, 0, 0, 0.5);

// glp badges color
$glp-badge--default-background-color: #f67a1a;
$glp-badge--default-color: #ffffff;
$glp-badge--primary-background-color: #248937;
$glp-badge--secondary-background-color: red;

// member tag color
$glp-member-tag-selected-color: #248937;
$glp-member-tag-border-color: rgba(109, 131, 139, 0.3);
$glp-member-tag-dark-background-color: #f6f7fa;
$glp-member-tag-light-background-color: white;
$glp-member-tag-title-color: #364145;

// glp call out colors
$glp-callout-text-color: #415167;
$glp-callout-background-color: #ffffff;
//$glp-callout-background-color:#efefef;

// Feature Callout
$glp-feat-callout-background-color: #248937;
$glp-feat-callout-white-color: #ffffff;
$glp-feat-callout-color: linear-gradient(
  32.23deg,
  #248937 10.78%,
  #97c83c 110.82%,
  #97c83c 110.82%
);

// Input Color
$glp-input--text-color: #364145;
$glp-input--background-color: #f5f6f9;
$glp-input--outline-border-color: #6d838b;
$glp-input--flat-border-color: #364145;
$glp-input--placeholder-color: #cccccc;
$glp-input--error-color: #ec1c24;
$glp-label--color: #474747;
$glp-label-flat-color: #6d838b;
$glp-input--background-transparent: transparent;

// glp button colors
$glp-button-default-color: #efefef;
$glp-button-text-color: #ffffff;
$glp-button-primary-color: #f3521e;
$glp-button-secondary-color: #248937;
$glp-button-danger-color: #e71b23;
$glp-button-info-color: #087ec2;

$glp-button-info: linear-gradient(180deg, #0ad0eb 0%, #087ec2 100%);
$glp-button-primary: linear-gradient(0deg, #f3521e 0%, #f67a1a 100%);
$glp-button-secondary: linear-gradient(180deg, #5da839 0%, #248937 100%);
$glp-button-danger: linear-gradient(180deg, #f42f36 0%, #e71b23 100%);

// glp feature card colors
$glp-feature-card-color: #232c38;
$glp-feature-card-content-color: #151d28;
$glp-feature-card-background-color: #ffffff;
$glp-feature-card-shadow: 0 0 0.71em rgba(210, 213, 222, 0.5);

//news item color
$glp-news-item-text-color: #415167;
$glp-news-item-link-text-color: #248937;

// glp news card colors
$glp-news-card-background-color: #ffffff;
$glp-news-card-text-color: #415167;
$glp-news-card-link-text-color: #248937;

//glp recipe direction color
$glp-recipe-direction-title-color: #6d838b;
$glp-recipe-direction-text-color: #364145;

//glp recipe ingredient color
$glp-recipe-ingredient-title-color: #6d838b;
$glp-recipe-ingredient-text-color: #364145;
$glp-recipe-ingredient-item-back-ground-color: #ffffff;
$glp-recipe-ingredient-item-box-shadow-color: rgba(210, 213, 222, 0.5);
$glp-recipe-ingredient-item-quantity-color: #248937;

// glp nutrition color
$glp-recipe-nutrition-title-color: #6d838b;
$glp-recipe-nutrition-text-color: #364145;
$glp-recipe-nutrition-item-back-ground-color: #ffffff;
$glp-recipe-nutrition-item-box-shadow-color: rgba(210, 213, 222, 0.5);
$glp-recipe-nutrition-item-quantity-color: #364145;
$glp-recipe-nutrition-item-name-color: #6d838b;

//glp recipe of day color
$glp-recipe-text-color: #ffffff;
$glp-recipe-name-color: #6d838b;
$glp-recipe-sub-container-color: #f6f7fa;

//glp Newsletter colors
$glp-news-letter-background-color: #364145;
$glp-news-letter-base-color: #ffffff;
$glp-news-letter-border-color: #e1e8ee;
$glp-news-letter-icon-background: linear-gradient(
  0deg,
  #f3521e 0%,
  #f67a1a 100%
);
$glp-news-letter-description-color: #6d838b;
$glp-news-letter-placeholder-color: rgba(0, 0, 0, 0.87);

//glp recipe item colors
$glp-recipe-item-background-color: #ffffff;
$glp-recipe-item-title-color: #474747;
$glp-recipe-item-category-color: #f67a1a;
$glp-recipe-item-circle-icon: #ec1c24;
$glp-recipe-item-box-shadow-color: rgba(210, 213, 222, 0.5);

//glp recipe card colors
$glp-recipe-card-background-color: #ffffff;
$glp-recipe-card-description-color: #474747;
$glp-recipe-card-category-color: #f67a1a;
$glp-recipe-card-category-circle: #ec1c24;
$glp-recipe-card-box-shadow-color: rgba(210, 213, 222, 0.5);

//glp search color
$glp-search-icon-background: #f3521e;
$glp-search-placeholder-color: #6d838b;
$glp-search-background-color: #f5f6f9;
$glp-search-border-color: #e1e8ee;
$glp-search-overlay-shadow-color: rgba(210, 213, 222, 0.5);
$glp-search-button-text-color: #ffffff;

// glp recalled item
$glp-recalled-item--background-color: #ffffff;
$glp-recalled-item--class-color: #ec1c24;
$glp-recalled-item--description-color: #364145;
$glp-recalled-item--price-color: #248937;
$glp-recalled-item-quantity-color: #6d838b;
$glp-recalled-item-danger-bar-color: #ec1c24;
$glp-recalled-item-danger-success-color: #248937;
$glp-recalled-item-danger-warning-color: #fcb813;
$glp-recalled-item-danger-info-color: #389ce1;
$glp-recalled-item-box-shadow: 0 4px 24px #cccccc;

// glp type ahead  colors
$glp-type-ahead-icon-background: #f3521e;
$glp-type-ahead-placeholder-color: #6d838b;
$glp-type-ahead-background-color: #364145;
$glp-type-ahead-border-color: #e1e8ee;
$glp-type-ahead-text-box-background-color: #f5f6f9;
$glp-type-ahead-button-text-color: #ffffff;
$glp-type-ahead-footer-border-color: #b2bfd6;
$glp-type-ahead-item-add-color: #248937;
$glp-type-ahead-overlay-shadow: rgba(210, 213, 222, 0.5);

// glp grocery list item
$glp-grocery-list-item-background-color: #ffffff;
$glp-grocery-list-item-background-shadow: rgba(210, 213, 222, 0.5);
$glp-grocery-list-item-size-color: #6d838b;
$glp-grocery-list-item-description-color: #364145;

//glp favourite disable color
$glp-favourite--disable: #6d838b;

// glp progress bar color
$glp-progress-bar-filled-color: #97c83c;
$glp-progress-bar-background-color: #cccccc;
$glp-progress-bar-linear-text-color: #6d838b;
$glp-progress-bar-circular-text-color: #f67a1a;

//glp digital coupon color
$glp-digital-coupon-background-color: #ffffff;
$glp-digital-coupon-shadow-color: rgba(210, 213, 222, 0.5);
$glp-digital-coupon-primary-color: #f67a1a;
$glp-digital-coupon-description-color: #364145;
$glp-digital-coupon-secondary-color: #248937;

//glp welcome menu component
$glp-welcome-menu-background-color: #ffffff;
$glp-welcome-menu-item-color: #6d838b;
$glp-welcome-menu-username-color: #248937;
$glp-welcome-menu-shadow-color: rgba(210, 213, 222, 0.5);

//glp header component
$glp-header-background-color: #ffffff;
$glp-header-price-color: #248937;
$glp-header-bar-color: #6d838b;
$glp-header-online-color: #0a75bf;
