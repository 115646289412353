.f-pass span.forgot {
  color: #f67a1a;
  font-size: 14px;
  line-height: 17.07px;
}

nav.bg-dark.login-nav.navbar.navbar-expand.navbar-light {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.fadeIn.first.m-3 h6 {
  font-weight: 600;
  color: #364145;
}

/*
Start - ag grid styles
*/

.ag-header-cell-label {
  color: #6d838b;
}

span.ag-header-icon.ag-header-cell-menu-button {
  display: none;
}

/*
End - ag grid styles
*/

/* Grid Search View */

/* css  written by rohit kumar */

.contract-model .modal-content .modal-header {
  padding: 1rem 2rem;
  border-bottom: unset;
}
.contract-model .modal-dialog {
  /* min-width: 69%; */
  min-width: 44% !important;
  max-width: 61%;
}
.contract-model .modal-content .modal-title h4 {
  margin: 20px 0px 0px 0;
  font-size: 20px;
  font-weight: 700;
}
.contract-model .modal-body ul {
  padding: 0 0 20px 20px;
}
.contract-model .modal-body ul li {
  font-weight: 300;
  margin-bottom: 15px;
}
.contract-model .modal-body p {
  font-weight: 300;
}
.glp-search__view__button {
  width: 25%;
}
/* .glp-search__view__text.glp-search-view-text1{
  max-width: 471px;
  width: 100%;
} */

/* Delete Model Css */

.delete-model .modal-dialog {
  max-width: 33% !important;
}
.delete-model .modal-content .modal-header {
  padding: 1rem 2rem 0 2rem;
  border-bottom: unset;
}
.delete-model .modal-content .modal-footer {
  border-top: unset;
  padding-top: 20px;
}
.delete-model .modal-content .modal-footer .glp-button--sm {
  min-width: 7.375em;
}
.delete-model .modal-content .modal-title h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0px 20px 0;
}
.delete-model .modal-content .modal-body p {
  font-weight: 300;
}

.product-inventory {
  padding: 10px 35px;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.error {
  background-color: red !important;
}
.val-error {
  color: red;
}

html {
  scroll-behavior: smooth;
}

.main-bg {
  background: #cfcfcf;
}
.glp-profile-relative {
  background: #fff;
  margin: 26px 0 0 0;
  display: inline-block;
  width: 100%;
}
.glp-profile-relative .glp-top-section {
  padding: 0px 0 0px 40px;
}
.glp-profile-relative .glp-profile-section h1 {
  padding: 36px 0 0px 0;
}
.glp-profile-relative .nav-tabs {
  border: none;
  padding: 0 10px 0 40px;
}
.glp-profile-relative .nav-item {
  color: #6d838b;
  padding: 13px 0 10px 0;
  position: relative;
  font-weight: 300;
  margin-right: 20px;
  border: none !important;
}
.glp-profile-relative .nav-item:before {
  width: 40px;
  content: "";
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
}

.glp-profile-relative .nav-item.active,
.glp-profile-relative .nav-item:hover {
  text-decoration: none;
  color: #248937 !important;
}
.glp-profile-relative .nav-item.active:before {
  background-color: #248937;
}
.edit-modal .modal-dialog {
  max-width: 600px;
}
.edit-modal .modal-header {
  border: none;
  padding: 14px 18px 0 50px;
}

.edit-modal .modal-header .modal-title {
  padding: 30px 0 0 0;
  font-weight: 600;
}
.edit-modal .modal-body {
  padding: 40px 35px 0 34px;
}
.edit-modal .form-section .form-group {
  margin-bottom: 24px;
}
.edit-modal .modal-footer {
  border: none;
  padding: 46px 28px 40px 32px;
}
.edit-modal .modal-footer .glp-button--sm {
  min-width: 7.375em;
}

.glp-profile-relative .glp-profile-content {
  background: #f6f7fa;
  min-height: 400px;
  padding: 40px 90px;
}
.glp-profile-relative .glp-profile-content .glp-media-object .glp-profile-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #efefef;
}
.glp-profile-relative .glp-profile-content .glp-media-object button {
  background: transparent;
  border: none;
  color: #248937;
  text-decoration: underline;
}
.glp-profile-relative .glp-form-section .form-group {
  margin-bottom: 2rem;
}
.glp-profile-relative .glp-form-section .glp-link {
  margin: 0 0 30px 0;
}
.payment-section .list-heading {
  font-size: 0.85em;
  color: #6d838b;
}
.payment-section .list-item {
  border-bottom: 1px solid #999;
  padding: 16px 0;
  font-size: 0.94em;
}
.payment-section .glp-link {
  font-size: 0.94em;
}
.payment-section .glp-button .glp-button--icon {
  margin: 0 0 0 12px;
}
.payment-section .glp-button {
  padding-left: 25px;
}
.glp-product-detail .main-section {
  background: #f6f7fa;
}
.glp-product-detail .white-bg {
  background: #fff;
  padding-top: 31px;
}
.glp-profile-relative.glp-product-detail .nav-item:before {
  bottom: 0.52px;
}
.glp-profile-relative.glp-product-detail .nav-item.active {
  border-bottom: 1px solid #dee2e6 !important;
}
.glp-profile-relative.glp-product-detail .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  background: #fff;
}
.glp-product-detail .left-content {
  background: #f6f7fa;
  border-top: 1px solid #dee2e6;
  overflow-y: scroll;
  height: 720px;
}
.glp-product-detail .left-content a {
  padding: 14px 10px 14px 20px;
  font-size: 0.8rem;
  display: block;
}
.glp-product-detail .left-content .glp-link {
  font-size: 0.9rem;
}
.glp-product-detail .left-content a:hover {
  text-decoration: none;
}
.glp-product-detail .left-content a.active {
  background: #fff;
  text-decoration: none;
}
.glp-product-detail .left-content a h6 {
  font-size: 0.95rem;
  color: #121739;
  font-weight: bold;
}
.glp-product-detail .left-content .bottom-text .text-grey {
  color: #6d838b;
}
.glp-product-detail .left-content .bottom-text .text-green {
  color: #248937;
}
.glp-profile-relative.glp-product-detail .glp-profile-content {
  background: #fff;
  min-height: 640px;
}
.glp-product-detail .product-inventory {
  padding: 10px 35px 50px 35px !important;
}
.glp-product-detail .glp-profile-content h5 {
  font-size: 0.9rem;
  margin: 30px 0 30px 0;
  font-weight: bold;
}
.glp-product-detail .glp-profile-content .border-b {
  border-bottom: 1px solid #b2bfd6;
  padding: 0 0 32px 0;
  margin: 0 0 30px 0;
}
.glp-product-detail .glp-profile-content .glp-input label {
  font-size: 0.82rem !important;
}
.glp-product-detail .glp-profile-content .glp-input {
  margin-left: 0;
}
.glp-product-detail .glp-profile-content .glp-button {
  padding: 0 1.5em;
}
.glp-grid-view .glp-top-section .glp-search .glp-search__view {
  max-width: 470px;
  width: 100%;
  margin-left: auto;
}
.glp-grid-view .glp-top-section {
  position: relative;
}
.glp-grid-view .glp-top-section .glp-add-btn {
  position: absolute;
  right: 35px;
  bottom: -35px;
}
.glp-grid-view .glp-top-section .glp-add-btn button {
  background: transparent;
  border: none;
  margin-right: 12px;
  color: #248937;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.product-inventory .image-section .image-card h4 {
  font-weight: bold;
  font-size: 0.9rem;
  color: #364145;
}

.product-inventory .image-section .image-row .image-card {
  flex-grow: 1;
  flex-basis: 0;
}
.product-inventory .image-section .card-image {
  display: block;
}
.product-inventory .image-section .card-image .image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background: #fafafa;
  border: 1px solid #cccccc;
  margin: auto;
}
.glp-profile-content .glp-button-outline--primary:hover {
  border: 1px solid #f3521e;
}

.glp-grocery .glp-promotion-banner img {
  width: 100%;
  border-radius: 10px;
}
.glp-grocery .glp-heading h1 {
  font-size: 1.5rem;
  font-weight: bold;
}
.glp-grocery .glp-grocery-section {
  background: #fff;
  border-radius: 10px;
}
.glp-grocery .glp-grid-info {
  border-bottom: 1px solid #6d838b;
}
.nav-tabs.default {
  border: none;
  border-bottom: 1px solid #fff;
  background: #fff;
  padding: 0 10px 0 40px;
}
.nav-tabs.default .nav-item {
  color: #6d838b;
  padding: 13px 0 10px 0;
  position: relative;
  font-weight: 400;
  margin-right: 20px;
  border: none !important;
}
.nav-tabs.default .nav-item:before {
  width: 30px;
  content: "";
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
}
.loader {
  background: #ffffff6b;
  left: 0;
  top: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  align-items: center;
  display: flex;
  padding-left: 40%;
}

.nav-tabs.default .nav-item.active,
.nav-tabs.default .nav-item:hover {
  text-decoration: none;
  color: #248937 !important;
}
.nav-tabs.default .nav-item.active:before {
  background-color: #248937;
}
.glp-grocery .nav-tabs.default .nav-item:nth-child(1) {
  margin-right: 100px;
}
.glp-grocery .nav-tabs.default .nav-item {
  padding: 13px 0 5px 0;
}
.glp-grocery .nav-tabs.default .nav-item:nth-child(2) {
  margin-right: 60px;
}

.glp-content-section {
  background: #f6f7fa;
  color: #6d838b;
}
.glp-content-section h6 {
  color: #6d838b;
  font-size: 1.2rem;
  font-weight: 400;
}
.glp-content-section .list-item {
  background: #fff;
  border: 1px solid #bbbbbb;
}
.glp-content-section .list-item .text-dark {
  font-weight: 400;
  line-height: 1rem;
}
.glp-content-section .list-item .right-content {
  font-size: 0.95rem;
}
.glp-content-section .list-item .right-content .glp-checkbox {
  margin-right: 0;
}
.glp-content-section .right-content .glp-checkbox .glp-checkbox-state label {
  padding-left: 0;
}
.glp-content-section .list-item .d-grid {
  display: grid;
  min-width: 120px;
}
.glp-content-section .list-item .text-sm {
  font-size: 0.8rem;
  width: 100%;
}
.glp-content-section .list-item .mark-box {
  border-left: 1px solid #b2bfd6;
}
.glp-content-section .list-item .mark-box span {
  width: 100px;
}
.glp-content-section .list-item .primary-text {
  width: 100%;
  font-weight: 600;
}
.secondary-text {
  color: #f67a1a;
}
.primary-text {
  color: #248937;
}

@media only screen and (min-width: 1300px) {
  .container {
    max-width: 1280px !important;
  }
}
@media only screen and (min-width: 1330px) {
  .container {
    max-width: 1320px !important;
  }
}

@media only screen and (max-width: 990px) and (min-width: 767px) {
  .glp-profile-relative .nav-list a {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 1280px) {
  .glp-profile-relative .nav-list a {
    margin-right: 40px;
  }
}
